<template>
    <div class="app-login">
        <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
            <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-container px-8 v-if="!resetSent && !error">
            <form @submit.prevent="submit">
                <v-row justify="center">
                    <v-col class="shrink">
                        <h2 class="text-no-wrap my-6">Reset Password</h2>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <p class="x-small--text text-uppercase"><b>Password</b></p>
                        <v-text-field ref="password" v-model="password" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="showPassword = !showPassword" class="field" dense filled solo flat prepend-inner-icon="mdi-lock-outline" label="" placeholder="Please enter your password..." required :rules="[rules.required]"> </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <p class="x-small--text text-uppercase"><b>Confirm Password</b></p>
                        <v-text-field ref="password" v-model="confirmPassword" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="showPassword = !showPassword" class="field" dense filled solo flat prepend-inner-icon="mdi-lock-outline" label="" placeholder="Please confirm your password..." required :rules="[rules.required]"> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block large color="primary" type="submit">Confirm</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.login' }" small text color="black">
                            <span class="text-capitalize font-weight-regular">Cancel</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>

        <v-container px-8 v-else-if="error">
            <v-row justify="center">
                <v-col class="shrink">
                    <h2 class="text-no-wrap my-6">Reset Password</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p v-text="error"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" :to="{ name: 'auth.login' }">Done</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container px-8 v-else>
            <v-row justify="center">
                <v-col class="shrink">
                    <h2 class="text-no-wrap my-6">Reset Password</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <p>Your password has been successfully reset.</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" :to="{ name: 'auth.welcome' }">Done</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";

/////////////////////////////////////////

export default {
    name: "reset",

    props: ["token"],

    mixins: [UserMixin],

    meta: {
        title: "Reset",
    },

    data() {
        return {
            details: null,
            loading: true,
            disabled: false,
            error: "",
            resetSent: false,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            rules: {
                required: (value) => !!value || "This field is required",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
            },
        };
    },

    created() {
        //When we first get to this page
        //we want to send the token to the server
        //so we can update the user's details

        //Check if there is an application
        var applicationContext = this.application && this.application.authenticationStyle == "application";

        this.$fluro.auth
            .retrieveUserFromResetToken(this.token, {
                application: applicationContext,
            })
            .then((userDetails) => {
                this.details = userDetails;

                //Update our state with the details
                this.firstName = userDetails.firstName;
                this.lastName = userDetails.lastName;
                this.email = userDetails.username || userDetails.email;
                this.lastName = userDetails.lastName;

                //We can stop loading now
                this.loading = false;
            })
            .catch((err) => {
                //There was a fail
                var message = this.$fluro.utils.errorMessage(err);

                // this.error = message
                this.loading = false;
            });
    },

    methods: {
        submit() {
            this.loading = true;

            var payload = {
                username: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                password: this.password,
                confirmPassword: this.confirmPassword,
            };

            /////////////////////////////////

            //Check if there is an application
            var applicationContext = this.application && this.application.authenticationStyle == "application";

            //Use the token to update the users details and login in as them
            this.$fluro.auth
                .updateUserWithToken(this.token, payload, { application: applicationContext })
                .then((user) => {
                    //Hi there user.firstName!
                    console.log("Were good!");
                    this.resetSent = true;
                    this.loading = false;
                })
                .catch(function (err) {
                    //There was a fail
                    var message = this.$fluro.utils.errorMessage(err);
                    this.error = message;
                    this.loading = false;
                });
        },
    },
};
</script>
